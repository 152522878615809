e4.product = {
    init: function () {
        if ( jQuery( '.js-e-product-form, .js-e-productlist' ).length > 0 ) {
            e4.util.require.js( e4.settings.require.jQueryValidate, e4.product.bindEvents );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
            e4.product.validateForm( domForm );
        } );
        jQuery( '.js-e-productlist' ).on( 'builded.e4.productlist', function ( event ) {
            jQuery( event.target ).find( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
                e4.product.validateForm( domForm );
            } );
        } );
    },
    validateForm: function ( domForm ) {
        var jForm = jQuery( domForm );
        jForm.validate( {
            submitHandler: function ( domForm ) {
                if ( jForm.is( '.js-e-product-form-removefromcart' ) === true ) {
                    e4.product.removeFromCart( domForm );
                }
                else /* if ( jForm.is( '.js-e-product-form-addtocart' ) === true )*/ {
                    e4.product.addToCart( domForm );
                }
            }
        } );
    },
    addToCart: function ( domForm ) {
        var jForm       = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.addProduct( objFormData );
        }
        else {
            e4.cart.addProduct( objFormData );
        }
    },
    removeFromCart: function ( domForm ) {
        var jForm       = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.removeProduct( objFormData );
        }
        else {
            e4.cart.removeProduct( objFormData );
        }
    }
};
